import { createUseStyles } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/theme/createUseStyles';

export const useStartPageStyles = createUseStyles(
  (theme) => ({
    wrapper: {
      padding: '1.5rem 3rem',
    },
    content: {
      display: 'flex',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
      backgroundSize: 'contain',
      backgroundColor: theme.background.paper,
      borderRadius: '0.25rem',
      flexDirection: 'column',
      minHeight: '400px',
      rowGap: '1.5rem',
      padding: '80px',
    },
    title: {
      font: {
        size: 32,
        weight: 700,
      },
      color: theme.palette.gloomyBlue.main,
    },
    desc: {
      width: '50%',
      fontSize: 18,
      lineHeight: '24px',
      color: theme.palette.gray.contrastText,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      margin: {
        top: 24,
        bottom: 40,
      },
    },
  }),
  { name: 'CompetenciesIntroPage' },
);
