import { FC, ReactElement } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { IModeration } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { ICourseStage } from '../../../../models/stores/course.store';
import { formatDate } from '../../../../shared/human-date';
import { ModerateField } from './moderate-field';
import { useFieldsStyles } from './use-styles';
import { requiredStatusCheck } from './utils';

interface IProps {
  stages: ICourseStage[];
}

interface IContent {
  name?: string;
  color?: string;
  startDate?: string;
  endDate?: string;
  procto?: string
}

export const Stages: FC<IProps> = ({ stages }) => {
  const classes = useFieldsStyles();

  const getContent = ({ _id }: IModeration): IContent => {
    const found = stages.find((stage) => stage._id === _id);

    if (!found) {
      return {};
    }

    const startDate = formatDate(found.start_date, found.timezone);
    const endDate = formatDate(found.end_date, found.timezone);
    const procto = found.is_procto ? 'Да' : 'Нет';

    return {
      name: found.name,
      color: found.color,
      startDate,
      endDate,
      procto,
    };
  };

  return (
    <FieldArray name="stages">
      {({ fields }): ReactElement => (
        <div className={classes.stages}>
          {fields.map((name, index) => {
            const content = getContent(fields.value[index]);

            return (
              <Field
                key={name}
                name={name}
                validate={requiredStatusCheck}
                render={(props: FieldRenderProps<IModeration>): ReactElement => (
                  <div className={classes.stage}>
                    <Chip label={content.name} customColor={content.color} />
                    <ModerateField
                      {...props}
                      className={classes.margin}
                      label={(
                        <div className={classes.row}>
                          <span>Дата начала оценки</span>
                          <span>Дата завершения оценки</span>
                          <span>Прокторинг</span>
                        </div>
                      )}
                      content={(
                        <div className={classes.row}>
                          <span>{content.startDate}</span>
                          <span>{content.endDate}</span>
                          <span>{content.procto}</span>
                        </div>
                      )}
                    />
                  </div>
                )}
              />
            );
          })}
        </div>
      )}
    </FieldArray>
  );
};
