import { FC, Fragment, PropsWithChildren, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { Loader } from '../../components/loader';

export const Auth: FC<PropsWithChildren> = observer((props) => {
  const appStore = useContext(appStoreContext);

  const { token, s3Token, loading } = appStore.user;

  useEffect(() => {
    appStore.user.getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iconSize = 48;

  const wrapperCN = 'w-screen h-screen flex items-center justify-center';
  const blockCN = 'max-w-xs flex flex-col items-center gap-y-4 p-4 text-primary-darkBlue font-semibold text-xl text-center';

  if (loading) {
    return (
      <div className={wrapperCN}>
        <div className={blockCN}>
          <Loader width={iconSize} height={iconSize} />
          <span>Выполняется проверка авторизации</span>
        </div>
      </div>
    );
  }

  if ((!token || !s3Token) && window.unione) {
    window.unione.goToAuth();

    return (
      <div className={wrapperCN}>
        <div className={blockCN}>
          <Loader width={iconSize} height={iconSize} />
          <span>Переход на страницу авторизации...</span>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
});
