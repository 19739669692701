import { FC } from 'react';
import { formatDate } from '../../../shared/human-date';
import { Hug } from '../../components/hug';
import { Comment } from './components/comment';
import { ICoursesReadonlyStageProps } from './course-readonly.models';
import { useCoursesReadonlyStageStyles } from './use-styles';

export const CoursesReadonlyStage: FC<ICoursesReadonlyStageProps> = (props) => {
  const startDate = formatDate(props.stage.start_date, props.stage.timezone);
  const endDate = formatDate(props.stage.end_date, props.stage.timezone);
  const classes = useCoursesReadonlyStageStyles();

  return (
    <Hug title={props.name} customColor={props.color}>
      <Comment
        content={(
          <div className={classes.wrapper}>
            <div className={classes.stageRow}>
              <div className={classes.label}>
                Дата начала оценки
              </div>
              <div>
                {startDate}
              </div>
            </div>
            <div className={classes.stageRow}>
              <div className={classes.label}>
                Дата окончания оценки
              </div>
              <div>
                {endDate}
              </div>
            </div>
            <div className={classes.stageRow}>
              <div className={classes.label}>
                Прокторинг
              </div>
              <div>
                {props.stage.is_procto ? 'Да' : 'Нет'}
              </div>
            </div>
          </div>
        )}
        moderation={props.moderation}
      />
    </Hug>
  );
};
