import { Outlet, RouteObject } from 'react-router-dom';
import { BrowserRoute } from '../../routes/browser.routes';
import { Layout } from '../components/layout/layout.view';
import { Access } from '../content/access';
import { Auth } from '../content/auth/auth.view';
import { CourseWrapper } from '../content/course-wrapper';
import { CoursesList } from '../content/courses-list';
import { Main } from '../content/main';
import { NotFound } from '../content/not-found';
import { StartPage } from '../content/start-page';
import { YandexMetrika } from '../content/yandex-metrika';

export const appRoutesDefinition: RouteObject[] = [{
  path: '/',
  element: (
    <YandexMetrika>
      <Auth>
        <Layout>
          <Access>
            <Outlet />
          </Access>
        </Layout>
      </Auth>
    </YandexMetrika>
  ),
  children: [{
    path: '*',
    element: <NotFound />,
  }, {
    path: '/',
    element: (
      <Main>
        <StartPage />
      </Main>
    ),
  }, {
    path: BrowserRoute.coursesList,
    element: (
      <Main>
        <CoursesList />
      </Main>
    ),
  }, {
    path: BrowserRoute.courseItem,
    element: (
      <Main>
        <CourseWrapper />
      </Main>
    ),
  }],
}];
