import { FC, Fragment, HTMLAttributes, ReactNode, useMemo } from 'react';
import { IBuilderCourse } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { StagesConstants } from '../../../constants/stages.constants';
import { IPaginationProps, Pagination } from '../../components/pagination';
import { Table } from '../../components/table';
import { ITableColumn } from '../../components/table/table.models';


interface ICoursesListTable extends IPaginationProps {
  courses: IBuilderCourse<string>[];
  rowRender: (row: IBuilderCourse<string>, props: Partial<HTMLAttributes<HTMLDivElement>>, columns: ReactNode[]) => ReactNode;
}


export const CoursesListTable: FC<ICoursesListTable> = (props) => {
  /**
   * Формируем колонки заголовков таблицы и значения каждой строки
   */
  const columns = useMemo<ITableColumn<IBuilderCourse<string>>[]>(() => [{
    name: 'Название оценочной сессии',
    value: (row) => <div className="line-clamp-2">{row.title}</div>,
  }, {
    name: 'Статус',
    value: (row) => StagesConstants[row.stage] ?? row.stage,
  }, {
    name: 'Дата изменения статуса',
    value: (row) => new Date(row.updated_at).toLocaleString('ru', {
      dateStyle: 'short',
    }),
  }, {
    name: 'Автор',
    value: ({ author }) => (author ? `${author!.sur_name} ${author!.name} ${author!.patronymic}` : 'нет данных'),
  }], []);


  return (
    <Fragment>
      <Table
        columns={columns}
        data={props.courses}
        rowRender={props.rowRender}
      />
      <Pagination
        total={props.total}
        page={props.page}
        minPage={props.minPage}
        maxPage={props.maxPage}
        limit={props.limit}
        limitsOptions={props.limitsOptions}
        onPrevClick={props.onPrevClick}
        onNextClick={props.onNextClick}
        onLimitChange={props.onLimitChange}
      />
    </Fragment>
  );
};
