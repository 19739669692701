import { FC, MouseEventHandler, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { AlertIcon } from '../../components/icons/alert';
import { ModeratorSidebar } from '../sidebar';
import { IMainProps } from './main.models';

const MIN_SIDEBAR_WIDTH = 296;
const MIN_CONTENT_WRAPPER_WIDTH = 1024;

// Blocker for content-manager, if he does not have role account-manager
const Plug: FC<{ url: string }> = ({ url }) => (
  <div className="mx-[28px] p-[32px] w-[100%] h-[min-content]
    flex flex-col gap-y-[24px] rounded-[8px] border
    border-solid border-elements-lines bg-elements-frost"
  >
    <AlertIcon />
    <p className="font-sans font-normal text-[16px] text-secondary-text">
      Чтобы увидеть оценочные сессии для модерации, вам необходимо быть аккаунт-менеджером организации.
    </p>
    <p className="font-sans font-normal text-[16px] text-secondary-text">
      Перейдите в раздел Организации и добавьте себя в качестве аккаунт-менеджера в требуемой организации.
    </p>
    <a
      href={url}
      className="font-sans font-medium text-[16px] text-primary-darkBlue"
    >
      Перейти
    </a>
  </div>
);

export const Main: FC<PropsWithChildren<IMainProps>> = observer((props) => {
  const { coursesByOrganization, config } = useContext(appStoreContext);
  const { error } = coursesByOrganization;
  const navigate = useNavigate();
  const [sidebarWidth, setSidebarWidth] = useState<number>(MIN_SIDEBAR_WIDTH);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const MAX_SIDEBAR_WIDTH = document.documentElement.clientWidth - MIN_CONTENT_WRAPPER_WIDTH;
  const windowHasResizeable = (MIN_CONTENT_WRAPPER_WIDTH + MIN_SIDEBAR_WIDTH) < document.documentElement.clientWidth;

  useEffect(() => {
    if (error) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const startResizing = (): void => {
    if (windowHasResizeable) {
      setIsResizing(true);
    }
  };

  const finishResizing = (): void => {
    setIsResizing(false);
  };

  const handleResize: MouseEventHandler = (event) => {
    if (!isResizing) {
      return;
    }

    const newWidth = event.clientX;

    if (newWidth < MIN_SIDEBAR_WIDTH) {
      setSidebarWidth(MIN_SIDEBAR_WIDTH);
      return;
    }

    if (newWidth > MAX_SIDEBAR_WIDTH) {
      setSidebarWidth(MAX_SIDEBAR_WIDTH);
      return;
    }

    setSidebarWidth(newWidth);
  };

  return (
    <main className="flex w-full height-full grow bg-bg" onMouseMove={handleResize} onMouseUp={finishResizing}>
      <aside
        style={{ minWidth: MIN_SIDEBAR_WIDTH, maxWidth: windowHasResizeable ? sidebarWidth : MIN_SIDEBAR_WIDTH }}
        className="sticky top-[64px] max-h-[calc(100vh-64px)] overflow-auto scrollbar bg-white flex pt-4 pb-20 w-full"
      >
        {!error
          ? <ModeratorSidebar />
          : <Plug url={`${config.builderWEB}/organizations`} />
        }
      </aside>
      <div
        className="sticky top-[64px] h-[calc(100vh-64px)] group"
        onMouseDown={startResizing}
      >
        <div className={cn('h-full absolute pr-[1px] grow bg-elements-lines select-none', { 'cursor-col-resize group-hover:pr-[4px] group-active:pr-[4px]': windowHasResizeable })} />
      </div>
      <div
        style={{ minWidth: windowHasResizeable ? MIN_CONTENT_WRAPPER_WIDTH : 'initial', flexGrow: 1 }}
      >
        {props.children}
      </div>
    </main>
  );
});
