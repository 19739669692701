import { ChangeEventHandler, FC, ReactElement, useContext, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { IBuilderCoursesByOrganizations } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import useThrottleEffect from 'ahooks/es/useThrottleEffect';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { CoursesListQuery } from '../../../models/routes/courses.models';
import { BrowserRoute } from '../../../routes/browser.routes';
import { appStoreContext } from '../../../stores/context.store';
import { Block } from '../../components/block';
import { CoursesIcon } from '../../components/icons/courses';
import { SearchIcon } from '../../components/icons/search';

export const ModeratorSidebar: FC = observer(() => {
  const appStore = useContext(appStoreContext);
  const { data, loading, error } = appStore.coursesByOrganization;

  /**
   * Получаем текущий фильтр организаций
   */
  const [search] = useSearchParams();
  const organization = search.get(CoursesListQuery.organization);


  /**
   * Внутренние состояния компонента
   */
  const [searchValue, setSearchValue] = useState<string>('');
  const [organizations, setOrganizations] = useState<IBuilderCoursesByOrganizations[] | undefined>(data);


  /**
   * Событие изменения поискового запроса
   */
  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target.value);
  };


  /**
   * Событие фильтрации списка организаций
   */
  const onFilter = (): void => {
    setOrganizations(() => {
      const needle = searchValue.trim().toLocaleLowerCase();
      if (needle && data) {
        return data.filter((org) => org.organization.name.toLowerCase().includes(needle));
      }
      return data;
    });
  };


  /**
   * Применяем поисковый запрос к списку организаций с задержкой
   */
  useThrottleEffect(onFilter, [searchValue], {
    wait: 300,
  });


  /**
   * Запрашиваем список организаций при подключении компонента
   */
  useEffect(() => {
    appStore.coursesByOrganization.getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   * Обновляем внутренний список организации при получении данных из стора
   */
  useEffect(() => {
    setOrganizations(data);
  }, [data]);


  /**
   * Сбрасываем поисковый запрос при обновлении фильтра организаций
   */
  useEffect(() => {
    setSearchValue('');
  }, [organization]);

  return (
    <Block
      loading={loading}
      error={error}
      data={organizations}
    >
      {(organizationsData): ReactElement => (
        <div className="flex flex-col w-full">
          <div className="flex gap-y-2 items-center mb-4 mx-6">
            <label className="w-full py-2.5 px-4 flex items-center border border-elements-lines rounded">
              <input
                className="w-full outline-none placeholder-primary-chipText leading-4"
                type="text"
                placeholder="Поиск по организациям"
                value={searchValue}
                onChange={onSearchChange}
              />
              <button
                type="submit"
                className="text-elements-gray"
              >
                <SearchIcon width={16} height={16} />
              </button>
            </label>
          </div>
          <div className="flex flex-col">
            <div className="flex gap-x-2 items-center font-semibold mx-6 py-2">
              <CoursesIcon className="text-status-warning min-w-[14px]" />
              <div>
                Оценочные сессии на модерации
              </div>
            </div>
            <div className="flex flex-col">
              {organizationsData.map((org) => {
                const OrganizationSearchParams = new URLSearchParams({
                  [CoursesListQuery.organization]: org.organization._id ?? '',
                  [CoursesListQuery.stage]: '',
                  [CoursesListQuery.page]: String(1),
                });

                const linkCN = classNames(
                  'flex justify-between py-2 pl-14 pr-8 text-text-secondary hover:bg-elements-hover2',
                  {
                    'bg-elements-hover2': organization === org.organization._id, // isActive
                  },
                );

                return (
                  <Link
                    className={linkCN}
                    to={{
                      pathname: BrowserRoute.coursesList,
                      search: OrganizationSearchParams.toString(),
                    }}
                    key={org.organization._id ?? org.organization.name}
                  >
                    <div className="line-clamp-2">
                      {org.organization.name}
                    </div>
                    <div className="text-primary-darkBlue">
                      {org.count}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Block>
  );
});
