export enum HttpCodes {
  success = 200,
  accepted = 202,
  noContent = 204,
  movedPermanently = 301,
  notModified = 304,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  notAcceptable = 406,
  timeout = 408,
  conflict = 409,
  requestedEntityTooLarge = 413,
  unprocessableEntity = 422,
  tooManyRequests = 429,
  exception = 500,
  notImplemented = 501,
  badGateway = 502,
  serviceUnavailable = 503,
  gatewayTimeOut = 504,
}
