export const integerParse = (value: unknown): number | undefined => {
  let result = undefined;
  if (typeof value === 'number') {
    result = value;
  }
  if (typeof value === 'string') {
    result = parseInt(value);
  }
  if (Number.isNaN(result)) {
    result = undefined;
  }
  return result;
};
