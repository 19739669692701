import { FC, useMemo } from 'react';
import cn from 'classnames';
import { ArrowLeftIcon } from '../icons/arrow-left';
import { ArrowRightIcon } from '../icons/arrow-right';
import { Select } from '../select';
import { IPaginationProps } from './pagination.models';

export const Pagination: FC<IPaginationProps> = (props) => {
  const isFirstPage = props.page === props.minPage;
  const isLastPage = props.page === props.maxPage;

  /**
    * Формируем текст с номерами текущих видимых элементов на странице
    */
  const countText = useMemo<string>(() => {
    const firstEl = Math.max(props.page, (props.page - 1) * props.limit);
    const lastEl = Math.min(props.page * props.limit, props.total);
    return `${firstEl}-${lastEl}`;
  }, [
    props.page,
    props.limit,
    props.total,
  ]);

  const btnCN = 'p-1 cursor-pointer';

  const prevBtnCN = cn(
    btnCN,
    isFirstPage ? 'text-elements-disabled' : 'text-primary-darkBlue',
  );

  const nextBtnCN = cn(
    btnCN,
    isLastPage ? 'text-elements-disabled' : 'text-primary-darkBlue',
  );

  return (
    <div className="flex mt-[1.625rem] justify-between items-center text-text-gray">
      <div>
        {`Всего записей: ${props.total}`}
      </div>
      <div className="flex gap-x-10">
        <div className="flex items-center">
          <span>На странице по:</span>
          <Select
            className="w-[75px] text-primary-darkBlue"
            options={props.limitsOptions}
            defaultValue={props.limit}
            onChange={props.onLimitChange}
            dropTop={true}
          />
        </div>
        <div className="flex items-center">
          {`${countText} из ${props.total}`}
        </div>
        <div className="flex items-center">
          <button
            onClick={props.onPrevClick}
            className={prevBtnCN}
          >
            <ArrowLeftIcon />
          </button>
          <button
            onClick={props.onNextClick}
            className={nextBtnCN}
          >
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
