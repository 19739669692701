import { FC } from 'react';
import cn from 'classnames';
import { Content } from '../../components/content';
import { useStartPageStyles } from './use-styles';

export const StartPage: FC = () => {
  const classes = useStartPageStyles();

  return (
    <Content>
      <div className={classes.wrapper}>
        <div className={cn(classes.content, 'bg-start-page shadow')}>
          <h1 className={classes.title}>Оценочные сессии</h1>
          <div className={classes.desc}>
            <p>Здесь вы увидите оценочные сессии, которые пользователи организаций создадут и отправят на модерацию.</p>
            <p>Нажмите на строку с оценочной сессией, чтобы открыть его для модерации.</p>
          </div>
        </div>
      </div>
    </Content>
  );
};
