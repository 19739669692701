import React from 'react';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'react-jss';
import { BrowserRouter } from 'react-router-dom';
import { appTheme } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/theme';
import { Wrapper } from '@unione-pro/unione.commons.wrapper';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { ErrorBoundaryWithLog } from './views/components/error-boundary-with-log';
import { Notifications } from './views/components/notifications';
import './index.scss';

const rootElement = document.getElementById('root');

if (rootElement !== null) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Wrapper>
        <BrowserRouter>
          <ThemeProvider theme={appTheme}>
            <Notifications>
              <ErrorBoundaryWithLog>
                <App />
              </ErrorBoundaryWithLog>
              <Toaster position="top-right" />
            </Notifications>
          </ThemeProvider>
        </BrowserRouter>
      </Wrapper>
    </React.StrictMode>,
  );
}
