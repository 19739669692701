import { FC } from 'react';
import classNames from 'classnames';
import { FileIcon } from '../icons/file';
import { IFileRowProps } from './file-row.models';

export const FileRow: FC<IFileRowProps> = ({ name, ...props }) => {
  const anchorProps: IFileRowProps = {
    ...props,
    className: classNames(
      props.className,
      'flex items-center gap-x-3 py-4 px-6 bg-sky-50 text-primary-darkBlue rounded',
    ),
  };
  return (
    <a {...anchorProps}>
      <FileIcon />
      <span>{name}</span>
    </a>
  );
};
