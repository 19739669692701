import { ISdkSaveModerateCourseArgs } from '@unione-pro/unione.assmnt.sdk.webapp';
import {
  IFieldsDisplay,
  IModeration,
  IModerationCourseBody,
} from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { ICourse } from '../models/stores/course.store';

const getModerateFieldValue = (
  status?: IModeration,
): IModeration => {
  if (!status) {
    return { comment: '' };
  }

  return {
    comment: status.comment,
    status: status.status,
  };
};

export class ModerationMappers {

  public static getInitialValues = (
    course: ICourse,
    fieldsDisplay: IFieldsDisplay,
    moderate?: IModerationCourseBody<string>,
  ): ISdkSaveModerateCourseArgs => {
    const initialValues: ISdkSaveModerateCourseArgs = {
      course_id: course._id,
      author_id: course.author_id,
      title: getModerateFieldValue(moderate?.title),
      organization_id: course.organization_id,
      stage: course.stage,
      stages: course.stages.map((stage) => {
        const stageModerate = moderate?.stages.find(
          (item) => item._id === stage._id,
        );

        return {
          ...getModerateFieldValue(stageModerate),
          _id: stage._id,
        };
      }),
      testing: course.testing.map((test) => {
        const testingModerate = moderate?.testing.find(
          (item) => item._id === test._id,
        );

        return {
          ...getModerateFieldValue(testingModerate),
          _id: test._id,
          tags: getModerateFieldValue(testingModerate?.tags),
        };
      }),
    };

    if (
      fieldsDisplay.show_academic_hours
      || fieldsDisplay.show_academic_months
    ) {
      initialValues.academic = {};

      if (fieldsDisplay.show_academic_hours) {
        initialValues.academic.hours = getModerateFieldValue(
          moderate?.academic?.hours,
        );
      }

      if (fieldsDisplay.show_academic_months) {
        initialValues.academic.month = getModerateFieldValue(
          moderate?.academic?.month,
        );
      }
    }

    if (fieldsDisplay.show_year) {
      initialValues.program_year = {
        _id: course.program_year?._id,
        ...getModerateFieldValue(moderate?.program_year),
      };
    }

    if (fieldsDisplay.show_branch_industry) {
      initialValues.dictionary_id = getModerateFieldValue(
        moderate?.dictionary_id,
      );
    }

    if (fieldsDisplay.show_description) {
      initialValues.desc = getModerateFieldValue(moderate?.desc);
    }

    if (fieldsDisplay.show_docs) {
      initialValues.files = course.files.map((file) => {
        const fileModerate = moderate?.files?.find(
          (item) => item.type === file.type,
        );

        return {
          ...getModerateFieldValue(fileModerate),
          orig_name: file.orig_name,
          url: file.url,
          type: file.type,
        };
      });
    }

    if (fieldsDisplay.show_qualification_name) {
      initialValues.qualification = {
        _id: course.qualification?._id,
        ...getModerateFieldValue(moderate?.qualification),
      };
    }

    if (fieldsDisplay.show_it) {
      initialValues.is_it = getModerateFieldValue(moderate?.is_it);
    }

    return initialValues;
  };

}
