import { IModeration, ModerationStatus } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable } from 'mobx';

interface IModalValue extends IModeration {
  name: string;
}

class ModerateFormStore {

  constructor() {
    makeAutoObservable(this);
  }

  public statuses: Record<string, ModerationStatus | undefined> = {};

  public isOpenEditModal: boolean = false;

  public editModalValue?: IModalValue;

  public setStatuses = (key: string, status?: ModerationStatus): void => {
    this.statuses[key] = status;
  };

  public setIsOpenEditModal = (isOpen: boolean): void => {
    this.isOpenEditModal = isOpen;

    if (!isOpen) {
      this.editModalValue = undefined;
    }
  };

  public setEditModalValue = (value: IModalValue): void => {
    this.editModalValue = value;
  };

  public resetStatuses = (): void => {
    this.statuses = {};
  };

  get hasNeedUpdateStatus(): boolean {
    return Object.values(this.statuses).some(
      (status) => status === ModerationStatus.needUpdates,
    );
  }

}

export const moderateFormStore = new ModerateFormStore();
