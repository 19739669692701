import { FC, HTMLProps, useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import { ChevronsIcon } from '../icons/chevrons';
import { ISelectOption, ISelectProps } from './select.models';

export const Select: FC<ISelectProps> = ({
  options,
  defaultValue,
  size = 'md',
  label,
  icon,
  className,
  dropTop = false,
  dropRight = false,
  error,
  disabled,
  onBlur,
  onChange,
  ...restProps
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  /**
   * Функция для чтения значения опций
   */
  const getOptionLabel = useCallback((option: ISelectOption): string => {
    if (typeof option === 'string') {
      return option;
    }
    if (typeof option === 'number') {
      return option.toString();
    }
    return option.label;
  }, []);


  /**
   * Объявляем состояния компонента
   */
  const [selected, setSelected] = useState<ISelectOption>(defaultValue ?? options[0]);
  const [isOpen, setOpen] = useState<boolean>(false);

  const selectedLabel = getOptionLabel(selected);


  /**
   * Свойства родительского блока
   */
  const wrapperProps: HTMLProps<HTMLDivElement> = {
    ...restProps,
    ref: wrapperRef,
    tabIndex: 1,
    className: cn(className, 'flex flex-col outline-none'),
    onBlur: (event) => {
      setOpen(false);

      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    },
  };


  /**
   * Коллбек показа выпадающего списка
   */
  const toggleDropdown = (): void => {
    setOpen((prev) => !prev);
  };


  /**
   * Коллбек изменения выбора
   */
  const onOptionChange = (option: ISelectOption): void => {
    setSelected(option);

    if (typeof onChange === 'function') {
      onChange(option);
    }
  };


  /**
   * Стили
   */
  const selectClasses = cn(
    'flex justify-between items-center appearance-none relative cursor-pointer',
    'placeholder-primary-gray py-2 outline-none select-none',
    {
      'border-red-500': error,
      'px-4': size === 'md',
      'px-1': size === 'sm',
      'bg-elements-lines': disabled,
    },
  );

  const dropDownClasses = cn(
    'absolute min-w-full rounded overflow-hidden z-[100]',
    'drop-shadow-[0px_4px_15px_rgba(0,0,0,0.05)] bg-white',
    dropTop ? 'bottom-[calc(100%+10px)]' : 'top-[calc(100%+10px)]',
    dropRight ? 'right-0' : 'left-0',
    isOpen ? 'visible' : 'invisible',
  );

  const iconClasses = cn(
    'transition cursor-pointer',
    {
      'right-1': size === 'sm',
      'right-4': size !== 'sm',
      'rotate-180': isOpen,
    },
  );

  return (
    <div {...wrapperProps}>
      {label &&
        <label className="text-text-secondary text-sm mb-1.5">
          {label}
        </label>
      }
      <div
        className="relative"
        onClick={toggleDropdown}
      >
        <div className={selectClasses}>
          <span>{selectedLabel}</span>
          {icon ?? <ChevronsIcon className={iconClasses} />}
        </div>
        <div ref={dropdownRef} className={dropDownClasses}>
          <div className="overflow-y-auto max-h-[300px] scrollbar scroll-py-2">
            {options.map((option) => {
              const optionLabel = getOptionLabel(option);
              const isCurrent = optionLabel === selectedLabel;

              const optionClasses = cn(
                'hover:bg-elements-hover2 px-4 py-2 cursor-pointer select-none whitespace-nowrap',
                isCurrent ? 'text-primary-darkBlue' : 'text-secondary-text',
              );

              const onOptionClick = (): void => {
                onOptionChange(option);
              };

              return (
                <div
                  key={optionLabel}
                  className={optionClasses}
                  onClick={onOptionClick}
                >
                  {optionLabel}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {error &&
        <div className="text-red-500 text-sm">
          {error}
        </div>
      }
    </div>
  );
};
