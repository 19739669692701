import { createUseStyles } from 'react-jss';

export const useModerateStyles = createUseStyles(
  () => ({
    wrapper: {
      padding: 40,
      display: 'flex',
      columnGap: 28,
    },
  }),
  { name: 'Moderate' },
);
