import { AxiosError } from 'axios';

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof AxiosError && error.response?.data) {
    const responseError: { message: string }
      = typeof error.response.data === 'string' ? JSON.parse(error.response.data) : error.response.data;

    return responseError.message;
  }

  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
};

export const getErrorCode = (error: unknown): number | undefined => {
  if (error instanceof AxiosError) {
    return error.response?.status;
  }

  return undefined;
};
