interface IUseS3ImageOpts {
  baseURL: string;
  s3Token?: string | null;
}

interface IUseS3ImageRes {
  getS3URL(src: string): string;
}

export const useS3URL = ({ baseURL, s3Token }: IUseS3ImageOpts): IUseS3ImageRes => {
  const getS3URL = (src: string): string => {
    const s3URL = new URL(baseURL + src);

    if (s3Token) {
      s3URL.searchParams.set('s3token', s3Token);
    }

    return s3URL.toString();
  };

  return { getS3URL };
};
