import { FC, ReactElement, useContext } from 'react';
import { Field, FieldRenderProps, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { IBuilderCourseFile, IModerationFile, ModerationStatus } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { useS3URL } from '../../../../shared/use-s3-url';
import { appStoreContext } from '../../../../stores/context.store';
import { FileIcon } from '../../../components/icons/file';
import { ModerateField } from './moderate-field';
import { useFilesStyles } from './use-styles';
import { requiredStatusCheck } from './utils';

const FILE_LABELS: Record<string, string> = {
  enrollment_order: 'Приказ о зачислении',
  students_list: 'Список обучающихся',
  educational_program: 'Образовательная программа',
};

interface IFileRowProps extends IModerationFile {
  content: string;
}

const FileRow: FC<IFileRowProps> = (props) => {
  const appStore = useContext(appStoreContext);
  const { config, user } = appStore;

  const { getS3URL } = useS3URL({ baseURL: config.filesStorage, s3Token: user.s3Token });

  const isApproved = props.status === ModerationStatus.approved;
  const isNeedUpdates = props.status === ModerationStatus.needUpdates;

  const classes = useFilesStyles({ isApproved, isNeedUpdates });

  const fileURL = getS3URL(props.url);

  return (
    <a
      href={fileURL.toString()}
      target="_blank"
      rel="noreferrer"
      className={classes.file}
    >
      <FileIcon />
      <span>{props.content}</span>
    </a>
  );
};

interface IProps {
  files: IBuilderCourseFile[]
}

export const Files: FC<IProps> = ({ files }) => {
  const classes = useFilesStyles({});
  const { values } = useFormState();

  const getContent = (value: IModerationFile): string | undefined => {
    const found = files.find((file) => file.type === value.type);

    if (!found) {
      return undefined;
    }

    const content = FILE_LABELS[found.type];

    return content;
  };

  if (!values.files) {
    return null;
  }

  return (
    <Paper className={classes.paper}>
      <FieldArray name="files">
        {({ fields }): ReactElement => (
          <div className={classes.files}>
            {fields.map((name, index) => {
              const content = getContent(fields.value[index]);

              return (
                <Field
                  key={name}
                  name={name}
                  validate={requiredStatusCheck}
                  render={(props: FieldRenderProps<IModerationFile>): ReactElement => (
                    <ModerateField
                      {...props}
                      label={content}
                      content={
                        content ? (
                          <FileRow {...props.input.value} content={content} />
                        ) : (
                          undefined
                        )
                      }
                    />
                  )}
                />
              );
            })}
          </div>
        )}
      </FieldArray>
    </Paper>
  );
};
