import { sdkUpdateCourseStage } from '@unione-pro/unione.assmnt.sdk.webapp/lib/courses/update-course-stage';
import { CourseStage } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { makeAutoObservable } from 'mobx';
import { ICourseStageUpdateRequest } from '../models/requests/courses.models';
import { ICoursesStageStore } from '../models/stores/courses.stage.store';
import { IRootStore } from '../models/stores/root.store';

export class CoursesStageStore implements ICoursesStageStore {

  public readonly rootStore: IRootStore;

  public loading: boolean = false;
  public error?: string = undefined;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }


  public async updateStage(request: ICourseStageUpdateRequest): Promise<void> {
    await sdkUpdateCourseStage({
      baseURL: this.rootStore.config.builderAPI,
      token: this.rootStore.user.token,
      data: request,
    });

    if (request.stage === CourseStage.draft) {
      await this.rootStore.moderationCourses.deleteCourse(request.course_id);
    }
  }

}
