import { FC, HTMLProps, PropsWithChildren } from 'react';
import cn from 'classnames';

export const Actions: FC<PropsWithChildren<HTMLProps<HTMLDivElement>>> = (props) => {
  const { className, ...restProps } = props;
  const blockCN = cn(
    'sticky w-[6.5rem] top-[80px] h-min flex flex-col gap-6 px-2 py-7 bg-white rounded',
    className,
  );

  return (
    <div className={blockCN} {...restProps} />
  );
};
