export const DEFAULT_FIELDS_DISPLAY = {
  show_qualification_name: true,
  show_academic_hours: true,
  show_academic_months: true,
  show_branch_industry: true,
  show_description: true,
  show_docs: true,
  show_questions: true,
  show_year: true,
  show_it: true,
};

export const OLD_PROGRAM_YEAR = ['22/23', '23/24'];

export const COMPETENCIES_LEVEL: Record<string, string> = {
  basic_level: 'Минимально-исходный уровень',
  normal_level: 'Базовый уровень',
  advanced_level: 'Продвинутый уровень',
  expert_level: 'Экспертный уровень',
};
