import { createUseStyles } from 'react-jss';

interface IProps {
  isApproved: boolean;
  isNeedUpdates: boolean;
  hideActions: boolean;
  required: boolean;
}

export const useModerateFormStyles = createUseStyles(
  (theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      width: '100%',
    },
    paper: {
      padding: 32,
    },
    transparentBorder: {
      borderColor: 'transparent',
    },
    modelContent: {
      padding: '16px 0 8px',
    },
    header: {
      display: 'flex',
      columnGap: 32,
      gridColumn: '1/-1',
      color: theme.palette.disabled.main,
    },
    fields: {
      display: 'grid',
      alignItems: 'flex-start',
      rowGap: 24,
      columnGap: 28,
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
  }),
  { name: 'ModerateForm' },
);

export const useModerateFieldStyles = createUseStyles<string, IProps>(
  (theme) => ({
    field: {
      border: {
        width: 1,
        radius: 4,
      },
      borderColor: ({ isApproved, isNeedUpdates }): string => {
        if (isApproved) {
          return theme.palette.success.main;
        }

        if (isNeedUpdates) {
          return theme.palette.warning.main;
        }

        return theme.palette.lines.main;
      },
      padding: ['16px', '24px'],
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
    },
    header: {
      color: theme.palette.secondaryText.main,
      position: 'relative',
    },
    actions: {
      position: 'absolute',
      top: 0,
      right: 0,
      display: (props): string => (props.hideActions ? 'none' : 'flex'),
      columnGap: 16,
    },
    action: {
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.gloomyBlue.main,
      },
    },
    title: {
      width: '100%',
      fontSize: 14,
    },
    content: {
      wordBreak: 'break-word',
      color: ({ isApproved, isNeedUpdates }): string => {
        if (isApproved) {
          return theme.palette.success.main;
        }

        if (isNeedUpdates) {
          return theme.palette.warning.main;
        }

        return theme.palette.gloomyBlue.main;
      },
    },
    comment: {
      wordBreak: 'break-word',
      color: theme.palette.gloomyBlue.main,
      paddingTop: 16,
      borderTop: {
        width: 1,
        color: theme.palette.lines.main,
      },
    },
    emptyValue: {
      fontSize: 14,
      wordBreak: 'break-word',
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      fontWeight: (props): string => (props.required ? '500' : ''),
      color: (props): string => (!props.required ? theme.palette.secondaryText.main : theme.palette.error.main),
    },
  }),
  { name: 'ModerateField' },
);

export const useFilesStyles = createUseStyles<string, Partial<IProps>>(
  (theme) => ({
    paper: {
      padding: 32,
    },
    files: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
    },
    file: {
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      columnGap: 12,
      padding: ['16px', '24px'],
      background: ({ isApproved, isNeedUpdates }): string => {
        if (isApproved) {
          return '#F6FCF6';
        }

        if (isNeedUpdates) {
          return '#FFFBF5';
        }

        return theme.palette.hover.main;
      },
      color: ({ isApproved, isNeedUpdates }): string | undefined => {
        if (!isApproved && !isNeedUpdates) {
          return theme.palette.primary.main;
        }

        return undefined;
      },
      borderRadius: 4,
    },
  }),
  { name: 'Files' },
);

export const useFieldsStyles = createUseStyles(
  (theme) => ({
    stages: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      gridColumn: '1/-1',
    },
    row: {
      display: 'flex',
      width: '90%',
      justifyContent: 'space-between',
      columnGap: 16,

      '& > span': {
        width: 250,
      },
    },
    stage: {
      border: {
        width: 1,
        radius: 4,
        color: theme.palette.lines.main,
      },
    },
    margin: {
      margin: 24,
    },
    competency_level: {
      wordBreak: 'break-word',
    },
    hide: {
      display: 'none',
    },
  }),
  { name: 'Fields' },
);

export const useFormToolbarStyles = createUseStyles(
  (theme) => ({
    actions: {
      position: 'sticky',
      top: 100,
      height: 'min-content',
      padding: ['28px', '8px'],
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      color: theme.palette.secondaryText.main,
      fontSize: 12,
    },
    action: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 8,
      width: 88,
      textAlign: 'center',
      cursor: 'pointer',

      '&:disabled': {
        color: theme.palette.disabled.main,
      },

      '&:hover:enabled': {
        color: theme.palette.gloomyBlue.main,
      },
    },
  }),
  { name: 'FormToolbar' },
);

export const useEditCommentModalStyles = createUseStyles(
  (theme) => ({
    modal: {
      fontSize: 14,
    },
    title: {
      font: {
        size: 24,
        weight: 500,
        color: theme.palette.gloomyBlue.main,
      },
    },
    subtitle: {
      fontSize: 14,
      color: theme.palette.secondaryText.main,
      marginTop: 12,
      marginBottom: 36,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
    },
    label: {
      fontSize: 14,
      color: theme.palette.secondaryText.main,
    },
    textarea: {
      borderRadius: 4,
      borderWidth: 1,
      outline: 'none',
      resize: 'none',
      padding: ['8px', '16px'],
    },
    actions: {
      flexDirection: 'column',
    },
  }),
  { name: 'EditCommentModal' },
);
