import { FC, ReactElement, ReactNode, useContext, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CourseStage } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { observer } from 'mobx-react';
import { StagesConstants } from '../../../constants/stages.constants';
import { ICourseItemRoute } from '../../../models/routes/courses.models';
import { BrowserRoute } from '../../../routes/browser.routes';
import { appStoreContext } from '../../../stores/context.store';
import { Block } from '../../components/block';
import { Content } from '../../components/content';
import { IOption, ISelectOption, Select } from '../../components/select';
import { getRouteWithParams } from '../../routes/get-route-params';
import { StartPage } from '../start-page';
import { useCoursesFilters } from './courses-list.filters';
import { CoursesListTable } from './courses-list.table';


export const CoursesList: FC = observer(() => {
  const appStore = useContext(appStoreContext);
  const { data, total, loading, error } = appStore.courseList;


  /**
   * Состояние пагинации
   */
  const filters = useCoursesFilters({
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    limitsOptions: [10, 15, 20],
    total,
  });


  const options = useMemo<IOption[]>(() => ([{
    label: 'Все оценочные сессии',
    value: undefined,
  }, {
    label: StagesConstants[CourseStage.published],
    value: CourseStage.published,
  }, {
    label: StagesConstants[CourseStage.moderate],
    value: CourseStage.moderate,
  }, {
    label: StagesConstants[CourseStage.needUpdates],
    value: CourseStage.needUpdates,
  }, {
    label: StagesConstants[CourseStage.archived],
    value: CourseStage.archived,
  }]), []);


  const currentOption = options.find((o) => o.value === filters.stage);


  /**
   * Обновляем
   */
  const onStageChange = (option: ISelectOption): void => {
    if (typeof option !== 'string' && typeof option !== 'number') {
      filters.setStage(option.value);
    }
  };


  /**
   * Формируем действие при обновлении лимита строк
   */
  const onLimitChange = (option: ISelectOption): void => {
    if (typeof option === 'number') {
      filters.setLimit(option);
    }
  };


  /**
   * Формируем действие при обновлении страницы
   */
  const onPageChange = (page: number): void => {
    filters.setPage(page);
  };


  useEffect(() => {
    appStore.courseList.getList({
      organization: filters.organization,
      stages: filters.stage,
      limit: filters.limit,
      page: filters.page,
    });
    return () => {
      appStore.courseList.resetList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.organization,
    filters.stage,
    filters.limit,
    filters.page,
  ]);

  useEffect(() => {
    if (error) {
      toast({ text: error, type: 'error' });
    }
  }, [error]);

  if (!loading && total === 0 && !filters.stage && error) {
    return <StartPage />;
  }

  return (
    <Content>
      <div className="flex flex-col gap-y-6 px-12 py-6 flex-1">
        <div className="flex justify-between">
          <h1 className="text-[1.75rem] font-bold text-primary-gloomyBlue">
            Модерация
          </h1>
          <Select
            className="w-[220px] bg-white rounded shadow ml-auto"
            options={options}
            defaultValue={currentOption}
            onChange={onStageChange}
          />
        </div>
        <div className="flex flex-col bg-white rounded shadow pt-6 pb-8 px-10 w-full">
          <Block
            loading={loading}
            error={error}
            data={data}
          >
            {(courses): ReactElement => (
              <CoursesListTable
                total={total}
                page={filters.page}
                minPage={filters.minPage}
                maxPage={filters.maxPage}
                limit={filters.limit}
                limitsOptions={filters.limitsOptions}
                courses={courses}
                onPrevClick={(): void => onPageChange(filters.page - 1)}
                onNextClick={(): void => onPageChange(filters.page + 1)}
                onLimitChange={onLimitChange}
                rowRender={(row, rowProps, columns): ReactNode => {
                  const routeParams = getRouteWithParams<ICourseItemRoute>(BrowserRoute.courseItem, {
                    courseId: row._id,
                  });
                  return (
                    <Link
                      className={rowProps.className}
                      key={row._id}
                      to={`${routeParams}?organization=${row.organization_id}`}
                    >
                      {columns}
                    </Link>
                  );
                }}
              />
            )}
          </Block>
        </div>
      </div>
    </Content>
  );
});
