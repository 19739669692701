import { CourseFileType } from '@unione-pro/unione.assmnt.sdk.webapp';

interface IFileType {
  name: string;
  type: 'pdf' | 'spreadsheet';
}

export const getFileType = (input: unknown): IFileType | null => {
  const type = Object.values(CourseFileType).find((ft) => ft === input);
  if (!type) {
    return null;
  }

  const fileTypes: Record<CourseFileType, IFileType> = {
    enrollment_order: {
      name: 'Приказ о зачислении',
      type: 'pdf',
    },
    students_list: {
      name: 'Список обучающихся',
      type: 'spreadsheet',
    },
    educational_program: {
      name: 'Образовательная программа',
      type: 'pdf',
    },
  };

  return fileTypes[type];
};
