import { FC, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { CloseIcon } from '../icons/close';
import { NotificationsContext } from './notifications.context';
import { INotification, NotificationType } from './notifications.models';

export const NotificationItems: FC<INotification> = (props) => {
  const notifyCenter = useContext(NotificationsContext);

  const [progress, setProgress] = useState(0);
  const [intervalID, setIntervalID] = useState<number | null>(null);

  const typeClasses: Record<NotificationType, string> = {
    success: 'border-t-green-400 text-green-400',
    error: 'border-t-red-400 text-red-400',
    warning: 'border-t-yellow-400 text-yellow-400',
  };

  const progressColor: Record<NotificationType, string> = {
    success: 'bg-green-400',
    error: 'bg-red-400',
    warning: 'bg-yellow-400',
  };


  const wrapperClassName = classNames(
    'flex relative gap-3 my-2 rounded items-center shadow border-t-[3px] bg-white pr-12 px-4 py-4 min-w-[350px] w-[350px] overflow-hidden',
    typeClasses[props.type],
    props.className,
  );


  const breakTimer = (): void => {
    if (typeof intervalID === 'number') {
      window.clearInterval(intervalID);
    }
  };


  const onDelete = (): void => {
    breakTimer();
    if (typeof notifyCenter.remove === 'function') {
      notifyCenter.remove(props.id);
    }
  };


  const startTimer = (): void => {
    const max = 100;
    const step = 1;
    const timeoutDivider = 25;
    const time = props.timeout / timeoutDivider;

    const progressInterval = window.setInterval(() => {
      setProgress((prev) => {
        if (prev < max) {
          return prev + step;
        }
        onDelete();
        return max;
      });
    }, time);

    setIntervalID(progressInterval);
  };


  const startAutoDelete = (): void => {
    if (props.autoDelete) {
      startTimer();
    }
  };


  useEffect(() => {
    startAutoDelete();

    return () => {
      breakTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className={wrapperClassName}>
      <div className="text-primary-gloomyBlue">
        <p className="font-semibold whitespace-pre-line">
          {props.title}
        </p>
        {props.message && (
          <p>
            {props.message}
          </p>
        )}
      </div>
      <CloseIcon
        className="absolute top-2 right-2 w-4 h-4 transition cursor-pointer text-primary-gloomyBlue hover:text-gray-400"
        onClick={onDelete}
      />
      <div className="absolute left-0 bottom-px bg-gray-200 w-full h-px">
        <div
          className={`duration-300 h-full w-full ${progressColor[props.type] || 'bg-blue-300'}`}
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};
