import { FC } from 'react';
import { Link } from 'react-router-dom';
import { WithFooter } from '../../components/with-footer';

export const NotFound: FC = () => (
  <WithFooter>
    <div className="w-full h-[calc(100vh-64px)] min-h-full grid grid-rows-[1fr_100px] bg-bg">
      <div className="w-full h-full flex items-center justify-center">
        <div className="flex flex-col items-center gap-y-2 max-w-sm text-center">
          <img
            className="h-full w-full md:h-auto md:w-auto max-w-[610px] max-h-[380px] mt-24 mb-16"
            src={`${process.env.PUBLIC_URL}/images/not-found.svg`}
            alt="Not found"
          />
          <h1 className="font-monserrat font-semibold text-[28px] text-primary-gloomyBlue">
          Ошибочка вышла
          </h1>
          <p className="text-sm text-secondary-text mb-6">
          Данная страница устарела или просто не существует,
          перейдите на главную по ссылке ниже
          </p>
          <Link
            to={'/'}
            className="flex items-center text-sm text-white bg-gradient-to-r from-gradient-start to-gradient-end px-6 py-2 rounded"
          >
          На главную
          </Link>
        </div>
      </div>
    </div>
  </WithFooter>
);
