export interface ICourseItemRoute extends Record<string, string> {
  courseId: string;
}

export enum CoursesListQuery {
  organization = 'organization',
  stage = 'stages',
  page = 'page',
  limit = 'limit',
}
