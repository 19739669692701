import { FC } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { CourseStage } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { observer } from 'mobx-react';
import { CloseIcon } from '../../../components/icons/close';
import { DoneIcon } from '../../../components/icons/done';
import { RequestUpdatesIcon } from '../../../components/icons/request-updates';
import { moderateFormStore } from './moderate-form-store';
import { useFormToolbarStyles } from './use-styles';

const iconProps = {
  height: 20,
  width: 20,
};

export const FormToolbar: FC = observer(() => {
  const form = useForm();
  const { pristine, submitting, invalid } = useFormState();
  const navigate = useNavigate();
  const classes = useFormToolbarStyles();

  const { hasNeedUpdateStatus } = moderateFormStore;

  const disabled = pristine || submitting || invalid;

  const goToPrev = (): void => {
    navigate(-1);
  };

  const onPublished = (): void => {
    form.change('stage', CourseStage.published);
    form.submit();
  };

  const onNeedUpdates = (): void => {
    form.change('stage', CourseStage.needUpdates);
    form.submit();
  };

  return (
    <Paper className={classes.actions}>
      <button className={classes.action} onClick={goToPrev}>
        <CloseIcon {...iconProps} />
        <span>Закрыть</span>
      </button>
      <button
        onClick={onNeedUpdates}
        className={classes.action}
        disabled={disabled || !hasNeedUpdateStatus}
      >
        <RequestUpdatesIcon {...iconProps} />
        <span>Отправить на&nbsp;доработку</span>
      </button>
      <button
        onClick={onPublished}
        className={classes.action}
        disabled={disabled || hasNeedUpdateStatus}
      >
        <DoneIcon {...iconProps} />
        <span>Опубликовать</span>
      </button>
    </Paper>
  );
});
