export enum EnvKeys {
  moderateAPI = 'REACT_APP_ASSMNT_MODERATE_API',
  // builderAPI = 'REACT_APP_ASSMNT_BUILDERNEO_API',
  builderAPI = 'REACT_APP_ASSMNT_BUILDERNEXT_API',
  builderWEB = 'REACT_APP_ASSMNT_BUILDERNEO_WEB',
  modelsWEB = 'REACT_APP_ASSMNT_MODELS_WEB',
  assmnt360WEB = 'REACT_APP_ASSMNT_360_WEB',
  fileStorage = 'REACT_APP_ASSMNT_S3_BUCKET_API',
  localToken = 'REACT_APP_LOCAL_TOKEN',
  localS3Token = 'REACT_APP_LOCAL_S3_TOKEN',
  yandexMetrikaId = 'REACT_APP_ASSMNT_YANDEX_METRIKA_ID',
  loggerAPI = 'REACT_APP_LOGGER_API',
  loggerAPIKey = 'REACT_APP_LOGGER_API_KEY',
}

export interface IConfigStore {
  moderateAPI: string;
  builderAPI: string;
  builderWEB: string;
  modelsWEB: string;
  assmnt360WEB: string;
  imagesStorage: string;
  filesStorage: string;
  localToken: string | undefined;
  localS3Token: string | undefined;
  yandexMetrikaId: string | undefined;
  loggerAPI: string | undefined;
  loggerAPIKey: string | undefined;
}
