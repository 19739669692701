import { ILoggerStore } from '../models/stores/logger.store';
import { IRootStore } from '../models/stores/root.store';
import { ConfigStore } from './config.store';
import { CourseStore } from './course.store';
import { CoursesListStore } from './courses.list.store';
import { CoursesByOrganizationStore } from './courses.organizations.store';
import { CoursesStageStore } from './courses.stage.store';
import { LoggerStore } from './logger.store';
import { ModerationCoursesStore } from './moderation.courses.store';
import { RoleStore } from './role.store';
import { UserStore } from './user.auth.store';

export class RootStore implements IRootStore {

  public readonly config: ConfigStore;
  public readonly user: UserStore;
  public readonly role: RoleStore;
  public readonly course: CourseStore;
  public readonly courseList: CoursesListStore;
  public readonly coursesStage: CoursesStageStore;
  public readonly coursesByOrganization: CoursesByOrganizationStore;
  public readonly moderationCourses: ModerationCoursesStore;
  public readonly logger: ILoggerStore;

  public get today(): Date {
    return new Date();
  }

  constructor() {
    this.config = new ConfigStore();
    this.course = new CourseStore(this);
    this.moderationCourses = new ModerationCoursesStore(this);
    this.courseList = new CoursesListStore(this);
    this.coursesByOrganization = new CoursesByOrganizationStore(this);
    this.coursesStage = new CoursesStageStore(this);
    this.user = new UserStore(this);
    this.role = new RoleStore(this);
    this.logger = new LoggerStore(this);
  }

}
