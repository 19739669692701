import { FC, ReactElement, useContext } from 'react';
import { Form } from 'react-final-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ISdkSaveModerateCourseArgs } from '@unione-pro/unione.assmnt.sdk.webapp';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { useUnmount } from 'ahooks';
import arrayMutators from 'final-form-arrays';
import { observer } from 'mobx-react';
import { StagesConstants } from '../../../constants/stages.constants';
import { ICourse } from '../../../models/stores/course.store';
import { BrowserRoute } from '../../../routes/browser.routes';
import { getErrorMessage } from '../../../shared/error-message';
import { appStoreContext } from '../../../stores/context.store';
import { FormToolbar } from './components/form-toolbar';
import { ModerateForm } from './components/moderate-form';
import { moderateFormStore } from './components/moderate-form-store';
import { useModerateStyles } from './use-styles';

interface IProps {
  course: ICourse;
}

export const CourseModerate: FC<IProps> = observer(({ course }) => {
  const appStore = useContext(appStoreContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const classes = useModerateStyles();

  const { resetStatuses } = moderateFormStore;

  const {
    initialValues = {},
    reset,
    sumbit,
  } = appStore.moderationCourses;

  useUnmount(() => {
    reset();
    resetStatuses();
  });

  const handleSubmit = async(values: ISdkSaveModerateCourseArgs): Promise<void> => {
    try {
      await sumbit(values);

      const stageText = StagesConstants[values.stage];

      toast({
        text: `Оценочной сессии присвоен статус "${stageText}"`,
        type: 'success',
      });

      navigate(
        `${BrowserRoute.coursesList}?organization=${searchParams.get(
          'organization',
        )}`,
      );
    }
    catch (err) {
      toast({
        text: getErrorMessage(err),
        type: 'error',
      });
    }
  };

  return (
    <Form
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={handleSubmit}
      render={(): ReactElement => (
        <div className={classes.wrapper}>
          <ModerateForm course={course} />
          <FormToolbar />
        </div>
      )}
    />
  );
});
