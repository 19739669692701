import { HTMLAttributes, ReactElement, ReactNode } from 'react';
import { ITableData, ITableProps } from './table.models';

export const Table = <TData extends ITableData>(props: ITableProps<TData>): ReactElement => (
  <div className="flex flex-col">
    <div className="flex text-secondary-text font-normal">
      {props.columns.map((col, index) => (
        <div
          key={index}
          className="flex flex-1 p-3 whitespace-nowrap"
          onClick={col.onClick}
        >
          {col.name}
        </div>
      ))}
    </div>
    <div className="flex flex-col">
      {props.data.map((row) => {
        const rowProps: HTMLAttributes<HTMLElement> = {
          className: 'flex text-primary-gloomyBlue border-b border-elements-lines cursor-pointer transition hover:bg-sky-50',
        };
        const columns = props.columns.map<ReactNode>((col, index) => (
          <div className="flex flex-1 p-3" key={index}>
            {col.value && col.value(row)}
          </div>
        ));

        if (props.rowRender) {
          return props.rowRender(row, rowProps, columns);
        }

        return (
          <div {...rowProps} key={row._id} >
            {columns}
          </div>
        );
      })}
    </div>
  </div>
);
