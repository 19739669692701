import { IBuilderCourse } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import {
  ISdkGetModerateCoursesArgs,
  sdkGetModerateCourses,
} from '@unione-pro/unione.assmnt.sdk.webapp/lib/moderate/get-moderate-courses';
import { makeAutoObservable, runInAction } from 'mobx';
import { ResponseMappers } from '../mappers/response.mappers';
import { ICoursesListStore } from '../models/stores/courses.list.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class CoursesListStore implements ICoursesListStore {

  public readonly rootStore: IRootStore;

  public data?: IBuilderCourse<string>[] = undefined;
  public total: number = 0;
  public loading: boolean = false;
  public error?: string = undefined;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }


  public async getList(request: ISdkGetModerateCoursesArgs): Promise<void> {
    try {
      runInAction(() => {
        this.resetList();
        this.loading = true;
        this.error = undefined;
      });

      const response = await sdkGetModerateCourses({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.user.token,
        data: {
          ...request,
          // sort: 'updatedAt,-1',
        },
      });

      runInAction(() => {
        this.loading = false;
        this.data = ResponseMappers.getBuilderList(response);
        this.total = response.totalCount;
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
        this.resetList();
      });
    }
  }


  public resetList(): void {
    this.data = undefined;
    this.total = 0;
  }

}
