import { FC, Fragment, PropsWithChildren, useContext } from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';

export const YandexMetrika: FC<PropsWithChildren> = observer((props) => {
  const { config } = useContext(appStoreContext);

  const yandexMetrikaId = config.yandexMetrikaId;

  return (
    <Fragment>
      {yandexMetrikaId && (
        <YMInitializer
          accounts={[Number(yandexMetrikaId)]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            trackHash: true,
          }}
        />
      )}
      {props.children}
    </Fragment>
  );
});
