import { FC } from 'react';
import classNames from 'classnames';
import { StagesConstants } from '../../../constants/stages.constants';
import { toHumanDate } from '../../../shared/human-date';
import { IStageProps } from './stage.models';

export const CourseStageHeader: FC<IStageProps> = ({
  className,
  color = 'primary',
  stage,
  createdAt,
}) => {
  const humanDate = toHumanDate(createdAt);
  const humanStage = StagesConstants[stage];

  const wrapperClassName = classNames(
    className,
    'flex items-center gap-x-8',
  );

  const stageClassName = classNames(
    'py-1 px-4 rounded text-sm text-white',
    { 'bg-helsinki': color === 'helsinki' },
    { 'bg-primary-darkBlue': color === 'primary' },
  );

  return (
    <div className={wrapperClassName}>
      <div className={stageClassName}>
        {humanStage}
      </div>
      <div className="text-sm text-elements-disabled">
        {humanDate}
      </div>
    </div>
  );
};
