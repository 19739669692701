import { FC, useContext } from 'react';
import classNames from 'classnames';
import { appStoreContext } from '../../../stores/context.store';
import { ArrowLeftLongIcon } from '../../components/icons/arrow-left-long';

export const Topbar: FC = () => {
  const appStore = useContext(appStoreContext);

  const activeClassName = classNames(
    'text-primary-darkBlue border-primary-darkBlue',
  );

  return (
    <div className="relative">
      <hr className="w-full h-[2px] absolute bottom-0 bg-elements-lines opacity-50" />
      <div className="flex gap-x-2 px-12 relative topbar-text text-secondary-text">
        <a href={appStore.config.builderWEB} className="flex items-center gap-x-2.5 pr-4 py-2.5 border-b-2 hover:border-helsinki">
          <ArrowLeftLongIcon />
          <span>
            Вернуться к компетенциям и заданиям
          </span>
        </a>
        <div className={`px-4 py-2.5 border-b-2 ${activeClassName}`}>
          Модерация
        </div>
        <a
          href={appStore.config.modelsWEB}
          className="px-4 py-2.5 border-b-2 hover:border-helsinki"
        >
          Модели оценочных сессий
        </a>
        <a href={appStore.config.assmnt360WEB} className="px-4 py-2.5 border-b-2 hover:border-helsinki">
          Оценка 360
        </a>
      </div>
    </div>
  );
};
