import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { ModerationStatus } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Button, Modal, ModalFooter, ModalHeader, toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { observer } from 'mobx-react';
import { NoIcon } from '../../../components/icons/no';
import { moderateFormStore } from './moderate-form-store';
import { useEditCommentModalStyles } from './use-styles';

export const EditCommentModal: FC = observer(() => {
  const [comment, setComment] = useState<string>();
  const classes = useEditCommentModalStyles();

  const form = useForm();

  const { isOpenEditModal, setIsOpenEditModal, editModalValue } = moderateFormStore;

  useEffect(() => {
    setComment(editModalValue?.comment);
  }, [editModalValue]);

  const handleCommentChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setComment(event.target.value);
  };

  const handleClose = (): void => {
    setIsOpenEditModal(false);
  };

  const handleChangeRequest = (): void => {
    if (!editModalValue) {
      return;
    }

    if (!comment?.trim()) {
      toast({
        type: 'error',
        text: 'Пожалуйста укажите замечания к заполнению поля',
        loadingBar: true,
      });
      return;
    }

    const { name, ...editValue } = editModalValue;

    const value = {
      ...editValue,
      comment,
      status: ModerationStatus.needUpdates,
    };

    form.change(name, value);

    handleClose();
  };

  return (
    <Modal open={isOpenEditModal} onClose={handleClose} color="orange">
      <ModalHeader onClose={handleClose} color="orange">
        <NoIcon />
      </ModalHeader>

      <div>
        <h2 className={classes.title}>
          Причина отклонения
        </h2>
        <div className={classes.subtitle}>
          Опишите замечания к заполнению поля
        </div>
        <fieldset className={classes.wrapper}>
          <label className={classes.label}>
            Комментарий
          </label>
          <textarea
            className={classes.textarea}
            rows={3}
            value={comment}
            onChange={handleCommentChange}
          />
        </fieldset>
      </div>

      <ModalFooter className={classes.actions}>
        <Button size="sm" onClick={handleChangeRequest}>
          OK
        </Button>
        <Button size="sm" variantStyle="standard" onClick={handleClose}>
          Отмена
        </Button>
      </ModalFooter>
    </Modal>
  );
});
