import { FC, HTMLProps } from 'react';
import { Input } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/input';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/notification';
import cn from 'classnames';
import { DoneIcon } from '../../../components/icons/done';
import { NoIcon } from '../../../components/icons/no';
import { PencilIcon } from '../../../components/icons/pencil';
import { useEditWrapperStyles } from './use-styles';

interface IEditWrapperProps extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
  value?: string;
  editable?: boolean;
  onChange?: (changedValue: string) => void;
}

export const EditWrapper: FC<IEditWrapperProps> = ({
  value,
  editable,
  children,
  onChange,
}) => {
  const classes = useEditWrapperStyles();
  const { isOpen, data, open, close, change } = useModalState<string>(value);

  const handleOnApprove = (): void => {
    if (!data?.length) {
      toast({
        text: 'Отсутствует значение',
        type: 'error',
      });

      return;
    }

    if (data === value) {
      close();

      return;
    }

    onChange?.(data);
    close();
  };

  if (!editable) {
    return <>{children}</>;
  }

  if (isOpen) {
    return (
      <div className={cn(classes.wrapper, classes.editWrapper)}>
        <Input
          className={classes.input}
          defaultValue={value}
          onChange={(e): void => change(e.target.value)}
          state="default"
          type="text"
          autoFocus
        />
        <div className={classes.actions}>
          <NoIcon className={classes.icon} onClick={close} />
          <DoneIcon className={classes.icon} onClick={handleOnApprove} />
        </div>
      </div>
    );
  }

  return (
    <div className={cn(classes.wrapper, classes.readOnlyWrapper)}>
      {children}
      <PencilIcon className={classes.icon} onClick={(): void => open()} />
    </div>
  );
};
