import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { FieldRenderProps, useForm } from 'react-final-form';
import { IModeration, ModerationStatus } from '@unione-pro/unione.assmnt.sdk.webapp';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { DoneIcon } from '../../../components/icons/done';
import { NoIcon } from '../../../components/icons/no';
import { PencilIcon } from '../../../components/icons/pencil';
import { WarningIcon } from '../../../components/icons/warning';
import { moderateFormStore } from './moderate-form-store';
import { useModerateFieldStyles } from './use-styles';

interface IProps extends FieldRenderProps<IModeration> {
  associatedFieldName?: string;
  label?: string | ReactElement;
  content?: string | number | ReactElement;
  required?: boolean;
  className?: string;
  hideActions?: boolean;
  associatedFieldCB?: (name: string) => void;
}

export const ModerateField: FC<IProps> = observer((props) => {
  const {
    required = true,
    input,
    associatedFieldName = '',
    associatedFieldCB,
    className,
    content,
    label,
    hideActions = false,
  } = props;
  const { value, onChange, name } = input;
  const form = useForm();
  const { setStatuses, setIsOpenEditModal, setEditModalValue } = moderateFormStore;
  const initialAssociateFieldValue: IModeration | undefined = form.getFieldState(associatedFieldName)?.initial;
  const [initialAssociateField, setInitialAssociateField] = useState<IModeration | undefined>();

  const isApproved = value.status === ModerationStatus.approved;
  const isNeedUpdates = value.status === ModerationStatus.needUpdates;

  useEffect(() => {
    if (associatedFieldName && associatedFieldCB && value.status) {
      associatedFieldCB(associatedFieldName);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, associatedFieldName]);

  useEffect(() => {
    if (!initialAssociateFieldValue || !associatedFieldName) {
      return;
    }
    setInitialAssociateField(initialAssociateFieldValue);
  }, [associatedFieldName, initialAssociateFieldValue]);

  useEffect(() => {
    if (!associatedFieldName || !initialAssociateField) {
      return;
    }
    const associateFieldValue = { ...initialAssociateField };
    if (isNeedUpdates) {
      associateFieldValue.status = value.status;
      associateFieldValue.comment = value.comment;
    }
    form.change(associatedFieldName, associateFieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value.status, associatedFieldName, initialAssociateField]);

  useEffect(() => {
    setStatuses(name, value.status);
  }, [name, setStatuses, value.status]);

  const classes = useModerateFieldStyles({ isApproved, isNeedUpdates, hideActions, required });

  const handleApprove = (): void => {
    onChange({ ...value, status: ModerationStatus.approved });
  };

  const handleOpenModal = (): void => {
    setEditModalValue({ ...value, name });
    setIsOpenEditModal(true);
  };

  const contentValue = useMemo(() => {
    if (content || typeof content === 'number') {
      return content;
    }

    return (
      <div className={classes.emptyValue}>
        {required && <WarningIcon />}
        <span>
          Значение отсутствует
        </span>
      </div>
    );
  }, [classes, content, required]);

  if (!value) {
    return null;
  }

  return (
    <div className={cn(classes.field, className)}>
      <div className={classes.header}>
        <div className={classes.title}>{label}</div>
        <div className={classes.actions}>
          {!isApproved && (
            <DoneIcon className={classes.action} onClick={handleApprove} />
          )}
          <div className={classes.action} onClick={handleOpenModal}>
            {isNeedUpdates ? <PencilIcon /> : <NoIcon />}
          </div>
        </div>
      </div>
      <div className={classes.content}>{contentValue}</div>

      {value.comment && (
        <div className={classes.comment}>{value.comment}</div>
      )}
    </div>
  );
});
