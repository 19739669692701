import { ReactElement, useMemo } from 'react';
import { IModeration, ModerationStatus } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { DescriptionHTML } from '../../../components/description';
import { useCommentStyles } from './use-styles';

interface ICommentProps {
  label?: string;
  title?: string;
  content?: string | number | ReactElement;
  moderation?: Partial<IModeration>;
}

export const Comment = (props: ICommentProps): ReactElement => {
  const moderation: Partial<IModeration> = {
    status: props.content ? props.moderation?.status : ModerationStatus.needUpdates,
    comment: props.moderation?.comment,
  };
  const classes = useCommentStyles({ status: moderation.status });

  const isCommentVisible = useMemo(() => (
    typeof props.moderation?.comment === 'string' && props.moderation?.comment.length > 0 && props.moderation.status === ModerationStatus.needUpdates
  ), [props.moderation]);

  return (
    <div className={classes.wrapper}>
      {props.label &&
        <div className={classes.secondaryText}>
          {props.label}
        </div>
      }
      {props.title &&
        <div className={classes.label}>
          {props.title}
        </div>
      }
      <DescriptionHTML className={classes.valueColor}>
        {props.content}
      </DescriptionHTML>
      {isCommentVisible &&
        <div>
          {moderation.comment}
        </div>
      }
    </div>
  );
};
