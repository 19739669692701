import { FC } from 'react';
import { CourseStage } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { Actions, ActionsButton } from '../../components/actions';
import { IActionsButtonProps } from '../../components/actions/actions.models';
import { ArchiveIcon } from '../../components/icons/archive';
import { CloseIcon } from '../../components/icons/close';
import { DoneIcon } from '../../components/icons/done';
import { RequestUpdatesIcon } from '../../components/icons/request-updates';
import { TrashIcon } from '../../components/icons/trash';
import { ICoursesActionsProps } from './course-actions.models';

export const CoursesReadonlyActions: FC<ICoursesActionsProps> = (props) => {
  const actionIconSize = 20;

  const closeProps: IActionsButtonProps = {
    ...props.closeProps,
    title: 'Закрыть',
    children: (
      <CloseIcon width={actionIconSize} height={actionIconSize} />
    ),
    onClick: (): void => {
      if (typeof props.onClose === 'function') {
        props.onClose();
      }
    },
  };

  const needUpdatesProps: IActionsButtonProps = {
    ...props.needUpdatesProps,
    title: 'Отправить на доработку',
    children: (
      <RequestUpdatesIcon width={actionIconSize} height={actionIconSize} />
    ),
    onClick: (): void => {
      if (typeof props.onNeedUpdates === 'function') {
        props.onNeedUpdates();
      }
    },
  };

  const publishedProps: IActionsButtonProps = {
    ...props.publishedProps,
    title: 'Опубликовать',
    children: (
      <DoneIcon width={actionIconSize} height={actionIconSize} />
    ),
    onClick: (): void => {
      if (typeof props.onPublished === 'function') {
        props.onPublished();
      }
    },
  };

  const archivedProps: IActionsButtonProps = {
    ...props.archivedProps,
    title: 'Отправить в архив',
    children: (
      <ArchiveIcon width={actionIconSize} height={actionIconSize} />
    ),
    onClick: (): void => {
      if (typeof props.onArchived === 'function') {
        props.onArchived();
      }
    },
  };

  const deleteProps: IActionsButtonProps = {
    ...props.deleteProps,
    title: 'Удалить',
    children: (
      <TrashIcon width={actionIconSize} height={actionIconSize} />
    ),
    onClick: (): void => {
      if (typeof props.onDelete === 'function') {
        props.onDelete();
      }
    },
  };


  const iconListProps: IActionsButtonProps[] = [
    closeProps,
  ];

  if (props.course?.stage === CourseStage.moderate) {
    iconListProps.push(needUpdatesProps);
    iconListProps.push(publishedProps);
  }
  if (props.course?.stage === CourseStage.published) {
    iconListProps.push(needUpdatesProps);
    iconListProps.push(archivedProps);
  }
  if (props.course?.stage === CourseStage.archived) {
    iconListProps.push(deleteProps);
  }

  return (
    <Actions className="shadow">
      {iconListProps.map((buttonProps) => (
        <ActionsButton {...buttonProps} key={buttonProps.title} />
      ))}
    </Actions>
  );
};
