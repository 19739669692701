import { FC } from 'react';
import { IBuilderCourseTesting, IModeration } from '@unione-pro/unione.assmnt.sdk.webapp';
import { IExpertise } from '../../../../models/stores/course.store';
import { Comment } from './comment';

interface ITagsProps {
  testing: IBuilderCourseTesting[];
  expertise: IExpertise;
  tagModeration?: IModeration;
}

export const Tags: FC<ITagsProps> = ({ testing, expertise, tagModeration }) => {
  const testingTags = testing.find(
    (testingItem) => testingItem._id === expertise._id,
  )?.tags;

  const tagsTitle = testingTags?.map((tag) => tag.title)?.join(', ') ?? 'Теги отсутствуют';

  return (
    <Comment
      title={'Теги'}
      content={tagsTitle}
      moderation={tagModeration}
    />
  );
};
