import { FC, ReactElement, useState } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { IModeration, ITestingModeration, ModerationStatus, TestType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import cn from 'classnames';
import { COMPETENCIES_LEVEL } from '../../../../constants/course.constants';
import { IExpertise } from '../../../../models/stores/course.store';
import { ModerateField } from './moderate-field';
import { useFieldsStyles } from './use-styles';
import { requiredStatusCheck } from './utils';

interface IProps {
  testType: TestType
  expertiseList: IExpertise[];
}

interface IContent {
  testing?: string | ReactElement;
  tags?: string;
}

export const Testing: FC<IProps> = ({ testType, expertiseList }) => {
  const classes = useFieldsStyles();
  const [showTagsActions, setShowTagsActions] = useState<Record<string, boolean>>({});

  const handleShowTagsAction = (name: string): void => {
    if (!showTagsActions[name]) {
      setShowTagsActions((prev) => ({ ...prev, [name]: true }));
    }
  };

  const isSphereTestType = testType === TestType.sphere;

  const blockLabel = isSphereTestType ? 'Сферы' : 'Компетенции';
  const fieldLabel = isSphereTestType ? 'Сфера' : 'Компетенция';

  const getContent = (value: ITestingModeration): IContent => {
    const found = expertiseList.find((exp) => exp._id === value._id);

    if (!found) {
      return {};
    }

    const level = COMPETENCIES_LEVEL[found.level];
    const tags = found.tags?.map((tag) => tag.title)?.join(', ');

    return {
      testing: (
        <>
          {found.title} <span className={classes.competency_level}>{level}</span>
        </>
      ),
      tags,
    };
  };

  return (
    <FieldArray name="testing">
      {({ fields }): ReactElement => {
        const getCurrentStatus = (fieldIndex: number): ModerationStatus | undefined => {
          const fieldValue: IModeration = fields.value[fieldIndex];

          return fieldValue.status;
        };

        return (
          <div className={classes.stages}>
            <Chip label={blockLabel} />

            {fields.map((name, index) => {
              const { testing, tags } = getContent(fields.value[index]);
              const hideTags = getCurrentStatus(index) === ModerationStatus.needUpdates;

              return (
                <div key={name} className={classes.stage}>
                  <Field
                    name={name}
                    validate={requiredStatusCheck}
                    render={(props: FieldRenderProps<ITestingModeration>): ReactElement => (
                      <ModerateField
                        {...props}
                        associatedFieldName={`${name}.tags`}
                        className={classes.margin}
                        label={fieldLabel}
                        content={testing}
                        associatedFieldCB={handleShowTagsAction}
                      />
                    )}
                  />
                  <div className={cn({ [classes.hide]: hideTags })}>
                    <Field
                      name={`${name}.tags`}
                      validate={requiredStatusCheck}
                      render={(props: FieldRenderProps<IModeration>): ReactElement => (
                        <ModerateField
                          {...props}
                          className={classes.margin}
                          label="Теги"
                          content={tags}
                          required={false}
                          hideActions={!showTagsActions[`${name}.tags`]}
                        />
                      )}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        );
      }}
    </FieldArray>
  );
};
