import { ISdkSaveModerateCourseArgs, sdkSaveModerateCourse } from '@unione-pro/unione.assmnt.sdk.webapp';
import { sdkDeleteModerateCourse } from '@unione-pro/unione.assmnt.sdk.webapp/lib/moderate/delete-moderate-course';
import { sdkGetModerateCourseDoc } from '@unione-pro/unione.assmnt.sdk.webapp/lib/moderate/get-moderate-course-doc';
import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { ModerationMappers } from '../mappers/moderation.mappers';
import { HttpCodes } from '../models/requests/http.models';
import { IModerationCoursesStore } from '../models/stores/moderation.courses.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class ModerationCoursesStore implements IModerationCoursesStore {

  public readonly rootStore: IRootStore;

  public loading: boolean = false;
  public error: string | undefined = undefined;

  public initialValues?: ISdkSaveModerateCourseArgs;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  public reset = (): void => {
    this.initialValues = undefined;
  };

  public get = async(): Promise<void> => {
    const course = this.rootStore.course.data;
    const fieldsDisplay = this.rootStore.course.fieldsDisplay;

    if (!course) {
      return;
    }

    try {
      runInAction(() => {
        this.loading = true;
        this.error = undefined;
      });

      const response = await sdkGetModerateCourseDoc({
        baseURL: this.rootStore.config.moderateAPI,
        token: this.rootStore.user.token,
        data: { course_id: course._id },
      });

      runInAction(() => {
        this.loading = false;
        this.initialValues = ModerationMappers.getInitialValues(course, fieldsDisplay, response);
      });
    }
    catch (error) {
      runInAction(() => {
        if (error instanceof AxiosError && error?.response?.status === HttpCodes.notFound) {
          this.initialValues = ModerationMappers.getInitialValues(course, fieldsDisplay);
        }
        else {
          this.error = getErrorMessage(error);
        }
        this.loading = false;
      });
    }
  };

  public sumbit = async(data: ISdkSaveModerateCourseArgs): Promise<void> => {
    try {
      runInAction(() => {
        this.loading = true;
        this.error = undefined;
      });

      await sdkSaveModerateCourse({
        baseURL: this.rootStore.config.moderateAPI,
        token: this.rootStore.user.token,
        data,
      });

      await this.rootStore.coursesStage.updateStage({
        course_id: data.course_id,
        stage: data.stage,
      });

      runInAction(() => {
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
        throw new Error(getErrorMessage(error));
      });
    }
  };

  /**
   * Удаление данных модерации курса
   */
  public async deleteCourse(courseId: string): Promise<void> {
    await sdkDeleteModerateCourse({
      baseURL: this.rootStore.config.moderateAPI,
      token: this.rootStore.user.token,
      data: { courseId },
    });
  }

}
