import { IBuilderCoursesByOrganizations } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { sdkGetModerateOrganizations } from '@unione-pro/unione.assmnt.sdk.webapp/lib/moderate/get-moderate-organization-courses';
import { makeAutoObservable, runInAction } from 'mobx';
import { ICoursesByOrganizationsStore } from '../models/stores/courses.organizations.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class CoursesByOrganizationStore implements ICoursesByOrganizationsStore {

  public readonly rootStore: IRootStore;

  public data?: IBuilderCoursesByOrganizations[] = undefined;
  public loading: boolean = false;
  public error?: string = undefined;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  public async getOrganizations(): Promise<void> {
    try {
      runInAction(() => {
        this.data = undefined;
        this.loading = true;
        this.error = undefined;
      });

      const response = await sdkGetModerateOrganizations({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.user.token,
      });

      runInAction(() => {
        this.loading = false;
        this.data = response;
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.data = undefined;
        this.loading = false;
        this.error = getErrorMessage(error);
      });
    }
  }

}
