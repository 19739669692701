import { FC, HTMLProps } from 'react';
import { WarningIcon } from '../icons/warning';

export const DescriptionHTML: FC<HTMLProps<HTMLDivElement>> = (props) => {
  const contentProps: HTMLProps<HTMLDivElement> = {
    ...props,
  };

  if (typeof props.children === 'string') {
    contentProps.children = undefined;
    contentProps.dangerouslySetInnerHTML = {
      __html: props.children,
    };
  }
  else if (props.children) {
    contentProps.children = props.children;
  }
  else {
    contentProps.children = (
      <div className="flex items-center gap-x-2 text-status-error">
        <WarningIcon />
        <span className="text-sm font-semibold">
          Значение отсутствует
        </span>
      </div>
    );
  }

  return (
    <div {...contentProps} />
  );
};
