import { FC, ReactElement, useContext, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CourseStage } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { observer } from 'mobx-react';
import { ICourseItemRoute } from '../../../models/routes/courses.models';
import { appStoreContext } from '../../../stores/context.store';
import { Block } from '../../components/block';
import { Content } from '../../components/content';
import { CourseModerate } from '../course-moderate';
import { CourseReadonly } from '../course-readonly';

export const CourseWrapper: FC = observer(() => {
  const appStore = useContext(appStoreContext);
  const { data, error, loading } = appStore.course;

  const { courseId } = useParams<ICourseItemRoute>();
  const [search, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (typeof courseId === 'string') {
      appStore.course.getItem(courseId);
    }
    return () => {
      appStore.course.resetItem();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  useEffect(() => {
    if (data?.organization_id && !search.get('organization')) {
      setSearchParams({ organization: data?.organization_id! });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.organization_id]);

  return (
    <Content>
      <Block
        loading={loading}
        error={error}
        data={data}
      >
        {(courseData): ReactElement => {
          if (courseData.stage === CourseStage.moderate) {
            return (
              <CourseModerate course={courseData} />
            );
          }
          return (
            <CourseReadonly course={courseData} />
          );
        }}
      </Block>
    </Content>
  );
});
