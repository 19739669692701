import { createUseStyles } from 'react-jss';

export const useCoursesReadonlyStageStyles = createUseStyles(
  (theme) => ({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },
    stageRow: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
    },
    label: {
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.gray.contrastText,
    },
  }),
  { name: 'Course-Stage' },
);

export const useCoursesReadonlyStyles = createUseStyles(
  (theme) => ({
    wrapper: {
      display: 'flex',
      flex: '1 1',
      padding: '24px 32px',
      columnGap: 24,
    },
    course: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1',
      rowGap: 24,
    },
    header: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      gap: 32,
      backgroundColor: theme.background.paper,
      borderRadius: 4,
      padding: 24,
      boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1)',
    },
    gridColSpanFull: {
      gridColumn: '1 / -1',
    },
    headerInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    headerTitleInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      gridColumn: 'span 2',
    },
    title: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '32px',
      wordBreak: 'break-word',
    },
    timeContent: {
      color: theme.palette.primary.main,
      marginTop: -8,
      fontSize: 30,
      fontWeight: 600,
      lineHeight: '36px',
    },
    contentBlock: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.background.paper,
      borderRadius: 4,
      padding: 24,
      rowGap: 40,
      boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1)',
    },
    transparentBorder: {
      borderColor: 'transparent',
    },
    modelContent: {
      padding: '16px 0 8px !important',
    },
    expertiseSeparator: {
      margin: '16px 0',
    },
    contentWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: 32,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
      wordBreak: 'break-word',
    },
    grayLabel: {
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.gray.contrastText,
    },
    files: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
  }),
  { name: 'Course' },
);
