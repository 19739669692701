import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import utc from 'dayjs/plugin/utc';

dayjs.locale(ru);
dayjs.extend(utc);

export const toHumanDate = (date: Date | string): string => new Date(date).toLocaleDateString('ru', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export const formatDate = (
  date: Date | string | number,
  timezone: number,
): string => dayjs(date)
  .utcOffset(timezone)
  .format(`DD MMMM YYYY HH:mm ([GMT${timezone > -1 ? '+' : ''}${timezone}])`);
