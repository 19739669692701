import { IBuilderCourseStage, IBuilderOrganization, IUserBuilder } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { sdkGetUser } from '@unione-pro/unione.assmnt.sdk.webapp/lib/user/get-user';
import { makeAutoObservable, runInAction } from 'mobx';
import { IRoleStore } from '../models/stores/role.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class RoleStore implements IRoleStore {

  public readonly rootStore: IRootStore;

  public data?: IUserBuilder<string, IBuilderOrganization<string>, IBuilderCourseStage<string>> = undefined;
  public loading: boolean = true;
  public error?: string = undefined;


  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }


  public async getBuilderUser(): Promise<void> {
    try {
      runInAction(() => {
        this.data = undefined;
        this.loading = true;
        this.error = undefined;
      });

      const response = await sdkGetUser({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.user.token,
      });

      runInAction(() => {
        this.data = response;
        this.loading = false;
      });
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

}
