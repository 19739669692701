import { FC, PropsWithChildren } from 'react';
import cn from 'classnames';
import { IHugProps } from './hug.models';

export const Hug: FC<PropsWithChildren<IHugProps>> = ({
  title = '',
  color = 'blue',
  customColor,
  className,
  contentClassName,
  children,
  ...props
}) => {
  const hugCN = cn(
    className,
    'flex flex-col border rounded',
  );

  const blockCN = cn(
    'self-start rounded-tl py-1 px-4 rounded-br',
    { 'text-white': !!customColor },
    { 'text-primary-darkBlue bg-sky-50': !customColor && color === 'blue' },
    { 'bg-status-green text-white': color === 'green' },
    { 'bg-text-visited text-white': color === 'purple' },
    { 'bg-status-warning text-white': color === 'yellow' },
  );

  const contentCN = cn(
    contentClassName,
    'flex flex-col flex-1 gap-y-4 p-6',
  );

  return (
    <div className={hugCN} {...props}>
      {title &&
        (<div className={blockCN} style={{ background: customColor }}>
          {title}
        </div>
        )}
      <div className={contentCN}>
        {children}
      </div>
    </div>
  );
};
