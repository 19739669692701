import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRoute } from '../../../routes/browser.routes';
import { WarningIcon } from '../icons/warning';
import { Loader } from '../loader';
import { IBlockProps } from './block.models';

export const Block = <TData extends object>(props: IBlockProps<TData>): ReactElement | null => {
  const stateBlockCN = 'w-full flex items-top justify-center py-16';

  if (props.loading === true || !props.data) {
    return (
      <div className={stateBlockCN}>
        <Loader width={24} height={24} />
      </div>
    );
  }

  if (!!props.error === true) {
    return (
      <div className={stateBlockCN}>
        <div className="flex flex-col items-center gap-y-2">
          <div className="flex flex-col items-center gap-y-2 text-status-error">
            <WarningIcon width={64} height={64} />
            <div className="text-lg font-semibold">
              Неизвестная ошибка
            </div>
          </div>
          <Link className="text-sm text-primary-darkBlue" to={BrowserRoute.coursesList} >
            Перейти на страницу оценочных сессий
          </Link>
        </div>
      </div>
    );
  }

  return props.children(props.data);
};
