import { FC, HTMLProps } from 'react';
import cn from 'classnames';
import { Topbar } from '../../content/topbar';

export const Content: FC<HTMLProps<HTMLDivElement>> = ({ children, ...props }) => {
  const className = cn('flex flex-col w-full', props.className);
  return (
    <div className={className} {...props}>
      <Topbar />
      {children}
    </div>
  );
};
