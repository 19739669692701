import { makeAutoObservable, runInAction } from 'mobx';
import { IRootStore } from '../models/stores/root.store';
import { IUserStore } from '../models/stores/user.store';
import { getErrorMessage } from '../shared/error-message';

export class UserStore implements IUserStore {

  public readonly rootStore: IRootStore;

  public token?: string = undefined;
  public s3Token?: string | null = undefined;
  public loading: boolean = true;
  public error?: string = undefined;


  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }


  public async getToken(): Promise<void> {
    try {
      runInAction(() => {
        this.token = undefined;
        this.loading = true;
        this.error = undefined;
      });

      const localToken = this.rootStore.config.localToken;
      if (typeof localToken === 'string' && localToken.length > 0) {
        runInAction(() => {
          this.token = localToken;
          this.loading = false;
        });

        await this.getS3Token();

        return;
      }

      if (window.unione) {
        const unioneToken = await window.unione.getUnioneToken();

        if (typeof unioneToken === 'string' && unioneToken !== 'null' && unioneToken.length > 0) {
          runInAction(() => {
            this.token = unioneToken;
            this.loading = false;
          });

          await this.getS3Token();

          return;
        }

        runInAction(() => {
          this.loading = false;
        });
      }
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

  public getS3Token = async(): Promise<void> => {
    try {
      const localS3Token = this.rootStore.config.localS3Token;
      if (typeof localS3Token === 'string' && localS3Token.length > 0) {
        runInAction(() => {
          this.s3Token = localS3Token;
        });

        return;
      }

      if (window.unione) {
        const unioneS3Token = await window.unione.getS3Token();

        if (typeof unioneS3Token !== 'string' || unioneS3Token === 'null' || unioneS3Token.length === 0) {
          runInAction(() => {
            this.s3Token = null;
          });
          return;
        }

        runInAction(() => {
          this.s3Token = unioneS3Token;
        });
      }
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
      });
    }
  };

}
