import { AxiosError } from 'axios';

export enum LOGGER_ACTION {
  ACTIVATED_ERROR_BOUNDARY = 'activated_error_boundary',
}

export interface ISendLogArgs {
  action: LOGGER_ACTION;
  request: object;
  response?: object;
  error?: AxiosError;
}

export interface ILoggerStore {
  sendLog(args: ISendLogArgs): Promise<void>;
}
