const ROUTE_SLASH = '/';
const ROUTE_PARAMS = ':';

export const getRouteWithParams = <TParams extends Record<string, string | undefined>>(
  url: string,
  params?: TParams,
): string => {
  if (!params) {
    return url;
  }

  const parts = url.split(ROUTE_SLASH).map<string>((urlPart) => {
    if (urlPart.startsWith(ROUTE_PARAMS) === false) {
      return urlPart;
    }

    const paramsKey = urlPart.replace(ROUTE_PARAMS, '');
    const paramsValue = params?.[paramsKey];

    if (!paramsValue) {
      return '';
    }

    return paramsValue;
  });

  const urlWithParams = parts.join(ROUTE_SLASH);
  return urlWithParams;
};
