import { FC } from 'react';
import cn from 'classnames';
import { IActionsButtonProps } from './actions.models';

export const ActionsButton: FC<IActionsButtonProps> = ({
  children,
  title,
  className,
  ...buttonProps
}) => {
  const buttonClassnames = cn(
    'flex flex-col items-center gap-y-2 text-xs text-text-secondary',
    buttonProps.disabled ? 'text-elements-disabled' : 'hover:text-primary-darkGray',
    className,
  );
  return (
    <button
      {...buttonProps}
      type="button"
      className={buttonClassnames}
    >
      {children}
      <span>{title}</span>
    </button>
  );
};
