import { useSearchParams } from 'react-router-dom';
import { CourseStage } from '@unione-pro/unione.assmnt.sdk.webapp/lib/models';
import { CoursesListQuery } from '../../../models/routes/courses.models';
import { integerParse } from '../../../shared/integer-parse';

interface ICoursesFiltersProps {
  limitsOptions: number[];
  total: number;
}

interface ICoursesFilters {
  limitsOptions: number[];
  minPage: number;
  maxPage: number;
  organization: string | undefined;
  stage: CourseStage | undefined;
  limit: number;
  page: number;
  setOrganization: (organization: string | undefined) => void;
  setStage: (option: string | undefined) => void;
  setLimit: (limit: number) => void;
  setPage: (page: number) => void;
}

export const useCoursesFilters = (props: ICoursesFiltersProps): ICoursesFilters => {
  const [search, setSearchParams] = useSearchParams();
  const organization = search.get(CoursesListQuery.organization) ?? undefined;
  const stageSearch = search.get(CoursesListQuery.stage);
  const limitSearch = search.get(CoursesListQuery.limit);
  const pageSearch = search.get(CoursesListQuery.page);


  const stage = Object.values(CourseStage).find((s) => s === stageSearch);
  const limit = integerParse(limitSearch) ?? props.limitsOptions[0];
  const page = integerParse(pageSearch) ?? 1;

  const minPage = 1;
  const maxPage = Math.ceil(props.total / limit) || 1;


  /**
   * Функция для фильтрации по статусу курсов
   */
  const setOrganization = (value: string | undefined): void => {
    search.set(CoursesListQuery.organization, value ?? '');
    search.set(CoursesListQuery.page, minPage.toString());
    setSearchParams(search.toString());
  };


  /**
   * Функция для фильтрации по статусу курсов
   */
  const setStage = (value: string | undefined): void => {
    const nextStage = Object.values(CourseStage).find((cs) => cs === value);

    search.set(CoursesListQuery.stage, nextStage ?? '');
    search.set(CoursesListQuery.page, minPage.toString());
    setSearchParams(search.toString());
  };


  /**
   * Функция для обновления лимита
   */
  const setLimit = (value: number): void => {
    const newLimit = props.limitsOptions.find((lo) => lo === value);
    if (newLimit) {
      search.set(CoursesListQuery.limit, newLimit.toString());
      search.set(CoursesListQuery.page, minPage.toString());
      setSearchParams(search.toString());
    }
  };


  /**
   * Функция для обновления номера страницы
   */
  const setPage = (newPage: number): void => {
    if (newPage >= minPage && newPage <= maxPage) {
      search.set(CoursesListQuery.page, newPage.toString());
      setSearchParams(search.toString());
    }
  };


  return {
    limitsOptions: props.limitsOptions,
    minPage,
    maxPage,
    organization,
    stage,
    limit,
    page,
    setOrganization,
    setStage,
    setLimit,
    setPage,
  };
};
